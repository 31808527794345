<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import StripeClient from "@services/StripeClient";
import StripePaymentMethods from "@services/StripePaymentMethods";
import { attachPaymentMethodToFacebookAdClient } from '@http/FacebookAdClients';

const props = defineProps<{
  facebookAdClientId: number,
  isOnboarding: boolean,
}>()

const emit = defineEmits(['save', 'cancel']);

const addingCard = ref<boolean>(false)

let stripeElements = undefined;
let cardElement: any = undefined;
let addressElement: any = undefined;

const errorContainer = ref();
const stripePaymentMethods = new StripePaymentMethods(StripeClient);

async function initStripe() {
  await StripeClient.initialize();
  stripeElements = StripeClient.client!.elements();

  // @ts-ignore
  cardElement = stripeElements.create('card', {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        border: '1px solid #eaeaea',
        padding: '10px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  });
  cardElement.mount('#card-element');

  const options = { mode: 'billing', allowedCountry: ['US', 'CA'], fields: { phone: 'always' } };

  // @ts-ignore
  addressElement = stripeElements.create('address', options);
  addressElement.mount('#address-element');
}

function dispatchSaveEvent(paymentMethodId: string) {
  const event = new CustomEvent('save', { detail: { paymentMethodId } });
  document.getElementById('collect-payment-method')?.dispatchEvent(event);
}

async function submit() {
  if (addingCard.value === true) {
    return
  }

  if (!cardElement || !addressElement) {
    console.error('Stripe elements are not initialized.');
    return;
  }

  const addressInfo = await addressElement.getValue();
  if (addressInfo.complete === false) {
    // if (resultContainer.value) {
    //   resultContainer.textContent = 'Address is incomplete';
    // }
    return;
  }

  console.log(addressInfo.value);

  try {
    addingCard.value = true
    const paymentMethod = await stripePaymentMethods.createPaymentMethod(cardElement, {
      name: addressInfo.value.name,
      phone: addressInfo.value.phone,
      address: {
        line1: addressInfo.value.address.line1,
        line2: addressInfo.value.address.line2,
        city: addressInfo.value.address.city,
        state: addressInfo.value.address.state,
        zip: addressInfo.value.address.postalCode,
        country: addressInfo.value.address.country,
      },
    });

    if (props.isOnboarding) {
      dispatchSaveEvent(paymentMethod.id);
    } else {
      await attachPaymentMethodToFacebookAdClient({
        stripePaymentMethodId: paymentMethod.id!,
        facebookAdClientId: props.facebookAdClientId,
      });

      errorContainer.value = '';
      emit('save');
    }
  } catch (error: any) {
    errorContainer.value = error.message
  }
  addingCard.value = false
}

function cancel() {
  emit('cancel');
}

watch(() => props.facebookAdClientId, async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    await initStripe();
  }
});

onMounted(() => {
  initStripe();
});
</script>

<template>
  <div :class="props.isOnboarding ? 'onboarding-container' : 'px-6 py-4 overflow-hidden rounded-md bg-white shadow'">
    <div class="text-lg font-thin mb-4" v-if="!props.isOnboarding">Add New Card</div>
    <div id="card-element" :class="props.isOnboarding ? 'mb-6 rounded-md border p-4' : 'mb-4 border p-2 rounded-md'"></div>
    <div id="address-element" :class="props.isOnboarding ? 'mb-6' : 'mb-4'"></div>
    <div id="card-result" class="mb-4 text-red-600">{{ errorContainer }}</div>
    <div :class="props.isOnboarding ? 'flex justify-between' : 'flex justify-end'">
      <button v-if="!props.isOnboarding" id="cancel-button" @click="cancel" :disabled="addingCard"
        class="bg-gray-400 hover:bg-gray-500 transition text-white px-4 py-1 text-sm rounded-md mr-2 disabled:bg-gray-200">
        Cancel
      </button>
      <button
        id="card-button"
        @click="submit"
        :disabled="addingCard"
        :class="props.isOnboarding
          ? [
              'flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
              addingCard ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 cursor-pointer'
            ]
          : [
              'rounded-md bg-orange-primary hover:bg-orange-primary-lite transition px-3 py-1 text-sm font-semibold text-white shadow-sm disabled:bg-orange-primary-lite focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-orange-500'
            ]">
        Save
      </button>
    </div>
  </div>
</template>
<style>
.onboarding-container {
  box-shadow: none;
}
</style>
