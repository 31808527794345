<script setup lang="ts">
import QuestionMarkIcon from '@components/general/QuestionMarkIcon.vue'
import { RailsVars } from '@utils'
import type { IChartNamedAreasDataset } from '@http/FacebookStats'

const props = defineProps<{
  namedAreas: IChartNamedAreasDataset
}>()

const isSuperAdmin = RailsVars.isSuperAdmin

console.log(props.namedAreas)

</script>
<template>
  <div class="flex justify-between">
    <div class="text-lg font-bold flex flex-col">
      {{ namedAreas.title }}
      <span class="text-base font-medium text-gray-600" v-html="namedAreas.description"></span>
    </div>

    <div class="flex flex-col gap-y-1 max-w-lg">
      <div v-if="!namedAreas.hasResults"
        class="text-base text-gray-500 dark:text-white gap-x-0.5 flex items-center"
        data-controller="tooltip"
        data-tooltip-max-width-value="500px"
        data-tooltip-text-value="Each localization group only runs for half of the month. If the selected date range doesn't include when this group runs, there'll be no results. If you think this is in error, please contact us."
      ><QuestionMarkIcon definedClasses="w-5 h-5 mt-0.5" />Why are there no results for these named areas?</div>
      <!-- <div class="text-sm text-gray-500 dark:text-white">
        Each localization group only runs for half of the month. If the selected date range doesn't include when this group runs, there'll be no results. If you think this is in error, please contact us.
      </div> -->
    </div>
  </div>

  <table class="mt-2 min-w-full divide-y divide-gray-300 rounded-sm border border-stroke bg-white py-6 px-7 dark:border-dark-strokedark dark:bg-dark-boxdark">
    <thead>
      <tr>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Zips</th>
        <th scope="col" class="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-8 dark:text-white">Named Area</th>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Impressions</th>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Reach</th>
        <th v-if="isSuperAdmin" scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Frequency</th>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Spend</th>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Clicks</th>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">CPC</th>
        <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">CPM</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
      <tr v-for="res in namedAreas.namedAreas">
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 min-w-[140px] max-w-[140px] overflow-hidden text-ellipsis dark:text-white hover:whitespace-wrap hover:text-wrap">
          <template v-if="res.zipCodes.length > 0">{{ res.zipCodes.join(', ') }}</template>
          <template v-else><p data-controller="tooltip" data-tooltip-text-value="This named area is geofenced to a subset of a zip code.">Targetted Geofence</p></template>
        </td>
        <td class="whitespace-nowrap pl-2 py-1 pr-3 text-sm font-medium text-gray-900 lg:pl-8 dark:text-white">{{ res.name }}</td>
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
          data-animated-number-start-value="0" :data-animated-number-end-value="res.impressions"
          data-animated-number-duration-value="750" data-animated-number-format="number">{{ res.impressions }}</td>
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
        data-animated-number-start-value="0" :data-animated-number-end-value="res.reach"
        data-animated-number-duration-value="750" data-animated-number-format="number">{{ res.reach }}</td>
        <td v-if="isSuperAdmin" class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
          data-animated-number-start-value="0" :data-animated-number-end-value="res.frequency"
          data-animated-number-duration-value="750" data-animated-number-decimals="2">{{ res.frequency || '-' }}</td>
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
          data-animated-number-start-value="0" :data-animated-number-end-value="res.spend"
          data-animated-number-duration-value="750" data-animated-number-format="currency">{{ res.spend || '-' }}</td>
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
          data-animated-number-start-value="0" :data-animated-number-end-value="res.clicks"
          data-animated-number-duration-value="750" data-animated-number-format="number">{{ res.clicks || '-' }}</td>
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
          data-animated-number-start-value="0" :data-animated-number-end-value="res.cpc"
          data-animated-number-duration-value="750" data-animated-number-format="currency">{{ res.cpc || '-' }}</td>
        <td class="whitespace-nowrap px-2 py-1 text-sm text-gray-500 dark:text-white" data-controller="animated-number"
          data-animated-number-start-value="0" :data-animated-number-end-value="res.cpm"
          data-animated-number-duration-value="750" data-animated-number-format="currency">{{ res.cpm || '-' }}</td>
      </tr>
      <tr v-if="namedAreas.namedAreas.length == 0">
        <td colspan="6" class="text-center text-gray-500 text-sm py-3 dark:text-white">No Available Data</td>
      </tr>
    </tbody>
  </table>
</template>