<script setup lang="ts">
import { defineProps, ref, computed } from 'vue'
import { v4 as uuid } from 'uuid'
import DotLoader from "@components/general/DotLoader.vue"
import FacebookNamedAreaResults from '@components/facebook_ads/FacebookNamedAreaResults.vue'
import { cumulativeSum } from '@utils'
import OverTime from '@components/facebook_ads/OverTime.vue'
import type { IQueryFacebookStatsForDashboardResponse } from "@http/FacebookStats"

const props = defineProps<{
  data?: IQueryFacebookStatsForDashboardResponse,
  dataKey: string,
  loading: boolean
}>()

const overTimeChartKey = ref<string>(uuid())
enum OverTimeChartMode {
  CUMULATIVE = 'cumulative',
  DAILY = 'daily',
}
const overTimeChartMode = ref<OverTimeChartMode>(OverTimeChartMode.CUMULATIVE)
const overTimeChartDatasetCumulative = computed(() => {
  if (props.data === undefined) {
    return undefined
  }

  const sourceData = props.data.datasets[graphMetric.value]
  // @ts-ignore
  const rollingSum: Array<number> = cumulativeSum(sourceData.data.map((d: string) => parseFloat(d)))

  return {
    dataset: {
      name: sourceData.name,
      data: rollingSum,
    },
    max: rollingSum[rollingSum.length - 1],
  }
})

const overTimeChartDataset = computed(() => {
  if (props.data === undefined) {
    return undefined
  }
  
  overTimeChartKey.value = uuid()
  if (overTimeChartMode.value === OverTimeChartMode.CUMULATIVE) {
    return overTimeChartDatasetCumulative.value
  } else if (overTimeChartMode.value === OverTimeChartMode.DAILY) {
    return {
      
      dataset: props.data!.datasets[graphMetric.value],
      max: props.data!.datasets[graphMetric.value].max,
    }
  }
})

enum GraphMetrics {
  IMPRESSIONS = 'impressions',
  SPEND = 'spend',
  CLICKS_TO_WEBSITE = 'clicksToWebsite',
  CLICKS = 'clicks',
}
const graphMetric = ref<GraphMetrics>(GraphMetrics.IMPRESSIONS)
</script>

<template>
  <div class="mt-4 max-w-[1200px] grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7 2xl:gap-7">
    <!-- ====== Chart One Start -->
    <div class="relative h-[424px] col-span-12 grid grid-cols-12 rounded-sm border border-stroke bg-white px-5 pt-7 pb-5 shadow-md dark:border-dark-strokedark dark:bg-dark-boxdark sm:px-7">
      <div v-if="loading" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <DotLoader />
      </div>
      <template v-else>
        <div class="col-span-12 lg:col-span-8 xl:col-span-9">
          <div class="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
            <div class="flex w-full flex-wrap gap-3 sm:gap-5">
              <div class="flex min-w-[11.875rem]">
                <span
                  class="mt-1 mr-2 flex h-[1rem] w-full max-w-[1rem] items-center justify-center rounded-full border border-brand-orange">
                  <span class="block h-[0.625rem] w-full max-w-[0.625rem] rounded-full bg-brand-orange"></span>
                </span>
                <div class="w-full">
                  <p class="font-semibold text-brand-orange">{{ data?.datasets[graphMetric]?.name }}</p>
                  <!-- <p class="text-sm font-medium"><%= start_date.strftime('%b %e') %> - <%= end_date.strftime('%b %e') %></p> -->
                </div>
              </div>
              <!-- <%# <div class="flex min-w-[11.875rem]">
                <span
                  class="mt-1 mr-2 flex h-[1rem] w-full max-w-[1rem] items-center justify-center rounded-full border border-brand-teal">
                  <span class="block h-[0.625rem] w-full max-w-[0.625rem] rounded-full bg-brand-teal"></span>
                </span>
                <div class="w-full">
                  <p class="font-semibold text-brand-teal">Total Sales</p>
                  <p class="text-sm font-medium">dates</p>
                </div>
              </div> %> -->
            </div>
            <!-- <div class="flex w-full max-w-45 justify-end">
              <div class="inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4">
                <button
                  class="rounded bg-white py-1 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-dark-boxdark dark:text-white dark:hover:bg-dark-boxdark">
                  Day
                </button>
                <button
                  class="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-dark-boxdark">
                  Week
                </button>
                <button
                  class="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-dark-boxdark">
                  Month
                </button>
              </div>
            </div> -->
          </div>
          <div>
            <OverTime v-if="overTimeChartDataset !== undefined && data !== undefined" :key="`${dataKey}-${overTimeChartKey}`" :labels="data!.labels" :dataset="overTimeChartDataset.dataset"
            :options="{ yMax: overTimeChartDataset.max }"></OverTime>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 rounded-sm px-4 py-4 col-span-12 lg:col-span-4 xl:col-span-3">
          <div>
            <div class="flex items-center justify-between mb-1">
              <h2 class="text-sm font-bold leading-6 text-gray-900">Display Mode</h2>
            </div>

            <div class="flex gap-x-3">
              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': overTimeChartMode === OverTimeChartMode.CUMULATIVE, 'border-gray-300': overTimeChartMode !== OverTimeChartMode.CUMULATIVE }"
                class="relative flex flex-1 justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none ">
                <button @click="overTimeChartMode = OverTimeChartMode.CUMULATIVE">Cumulative</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': overTimeChartMode === OverTimeChartMode.DAILY, 'border-gray-300': overTimeChartMode !== OverTimeChartMode.DAILY }"
                class="relative flex flex-1 justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="overTimeChartMode = OverTimeChartMode.DAILY">Daily</button>
              </label>
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between mb-1">
              <h2 class="text-sm font-bold leading-6 text-gray-900">Metric</h2>
            </div>

            <div class="flex flex-col gap-y-3">
              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.IMPRESSIONS, 'border-gray-300': graphMetric !== GraphMetrics.IMPRESSIONS }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none ">
                <button @click="graphMetric = GraphMetrics.IMPRESSIONS">Impressions</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.SPEND, 'border-gray-300': graphMetric !== GraphMetrics.SPEND }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="graphMetric = GraphMetrics.SPEND">Spend</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.CLICKS_TO_WEBSITE, 'border-gray-300': graphMetric !== GraphMetrics.CLICKS_TO_WEBSITE }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="graphMetric = GraphMetrics.CLICKS_TO_WEBSITE">Clicks to Website</button>
              </label>

              <label :class="{ 'border-brand-orange ring-1 ring-brand-orange': graphMetric === GraphMetrics.CLICKS, 'border-gray-300': graphMetric !== GraphMetrics.CLICKS }"
                class="relative justify-center cursor-pointer rounded-md border bg-white px-2 py-1 shadow-sm text-sm focus:outline-none">
                <button @click="graphMetric = GraphMetrics.CLICKS">All Clicks</button>
              </label>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- ====== Chart Two Start -->
    <!-- <include src="./partials/chart-02.html" /> -->
    <!-- ====== Chart Two End -->

    <!-- ====== Chart Three Start -->
    <!-- <include src="./partials/chart-03.html" /> -->
    <!-- ====== Chart Three End -->

    <!-- ====== Map One Start -->
    <!-- <include src="./partials/map-01.html" /> -->
    <!-- ====== Map One End -->

    <!-- ====== Table One Start -->
    <!-- <div class="col-span-12 xl:col-span-8">
      <include src="./partials/table-01.html" />
    </div> -->
    <!-- ====== Table One End -->

  </div>

  <div class="relative rounded border min-h-[250px] max-w-[1200px] mt-4 md:mt-6 2xl:mt-7 bg-white p-4 lg:p-8 shadow-md dark:border-dark-strokedark dark:bg-dark-boxdark">
    <div v-if="loading" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <DotLoader />
    </div>
    <FacebookNamedAreaResults v-else :key="dataKey" :named-areas="data!.datasets.namedAreas.data[0]" />
  </div>

  <div class="relative rounded border min-h-[250px] max-w-[1200px] mt-4 md:mt-6 2xl:mt-7 bg-white p-4 lg:p-8 shadow-md dark:border-dark-strokedark dark:bg-dark-boxdark">
    <div v-if="loading" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <DotLoader />
    </div>
    <FacebookNamedAreaResults v-else :key="dataKey" :named-areas="data!.datasets.namedAreas.data[1]" />
  </div>
</template>